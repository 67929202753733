module.exports = {
    message: {
        findSource: '为您找到以下资源',
		empty_message_not_allowed: '留言信息不能为空！',
		feedback_sent: "反馈已发送",
    },
    label: {
        chooseBox: '框选主体',
        productClass: '商品分类',
        synthesize: '综合',
        volumeOfTransaction: '成交额',
        price: '价格',
		negotiable: '询价',
        currentAddress: '所在地区',
        allAddress: '所有地区',
        purchaseFrom: '采购距离',
        startCount: '起订量',
        under: '以下',
        hour: '小时',
        shipments: '发货',
        goodMerchant: '实力商家',
		slogan: '搜货源, 智选品',
        no_more: '- 没有找到结果 -',
		leave_a_message: '留言',
		upload_pic: '上传截图',
		contact: '联系方式',
		message:'留言内容',
        projectName: '搜货源',
        cookieToast: '我们使用cookie来确保我们为您提供最佳的网站体验。如果您想更改自己的偏好，可以按照此处的说明进行。',
        detail: '详情',
		comingSoon: '新上',
		inventory: '库存',
		downloads: '下载次数',
		minValue: '最低',
		maxValue: '最高',
		lt: '小于',
		le: '小于等于',
		gt: '大于',
		ge: '大于等于',
    },
    button: {
        more: '更多',
		search: '搜索',
		cancel: '取消',
		confirm: '确认',
		submit: '提交',
		clearSearch: "清空搜索",
		save: '保存',
		agree: '同意',
		ok: '确认',
    }
}
