import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import SupportSourceList from "@/views/Search/components/support-source-list";

// store.store_id是全局依赖，所以放在这里在router/store/app.vue之前就做好初始化
// 如果插件没有安装，此时app-setting==null，getAppSetting会负责设置默认的source_id=0，否则VUE会处于一个无定义状态
store.commit('getAppSetting');

Vue.config.productionTip = false
Vue.use(VueI18n)
Vue.use(ElementUI);
Vue.component('support-source-list',  SupportSourceList);

// 创建多语言，并配置语言文件
export const i18n = new VueI18n({
    locale: 'en', // 默认语言标识
    messages: {
        'zh': require('@/lang/zh'),
        'en': require('@/lang/en'),
    }
})

document.title = i18n.t('label.projectName');

router.beforeEach(((to, from, next) => {
    if(to.path === '/') {
        return next(router.options.routes[1].children[store.state.source_id].meta.absPath);
    }
    next();
}))

let v = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')

