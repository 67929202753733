module.exports = {
    message: {
        findSource: 'Find the following products for you',
        no_login: 'Please login ',
        login_timeout: 'Current login expired, Please login ',
        un_login: 'Not logged',
        go_login: 'Go to login',
        get_result_error: 'Failed to obtain data',
        search_result_from_image_error: 'Image search failed',
        search_result_from_text_error: 'Text search failed',
        upload_image_error: 'Uploading image failed',
        network_error: 'Network Error',
        search_text_not_null: 'Search content cannot be empty!',
        no_result_found: '- No Results Found -',
        unknown_error: 'Unknown error occurred ',
        no_search_image_api: 'Image Search not supported!',
		empty_message_not_allowed: "Message can't be empty!",
		feedback_sent: "Feedback sent",
    },
    label: {
        chooseBox: 'Crop',
        productClass: 'Category',
        synthesize: 'Overall',
        volumeOfTransaction: 'Sales',
        price: 'Price',
		negotiable: 'Negotiable',
        currentAddress: '所在地区',
        allAddress: '所有地区',
        purchaseFrom: '采购距离',
        startCount: 'MOQ',
        under: 'Under',
        hour: 'Hours',
        shipments: 'Shipping',
        goodMerchant: '实力商家',
        slogan: 'Sourcing, Smartly',
        input_placeholder: 'Enter product keywords',
        select: 'Select',
		leave_a_message: 'Leave A Message',
		upload_pic: 'Upload Picture',
		contact: 'Contact',
		message:'Message',
		expand:'+',
		collapse:'-',
        spread_more: 'View More',
        pack_more: 'View Less',
        projectName: 'Sourcefrom',
        cookieToast: "This website uses cookies to improve your experience. We'll assume you're ok with this, but you can opt-out if you wish.",
        detail: 'Detail',
		comingSoon: 'NEW',
		inventory: 'Inventory',
		downloads: 'Downloads',
		minValue: 'min',
		maxValue: 'max',
		lt: 'less than',
		le: 'less or equal',
		gt: 'greater than',
		ge: 'greater or equal',
    },
    button: {
        more: 'More',
        search: 'Search',
        cancel: 'Cancel',
		confirm: 'Confirm',
		submit: 'Submit',
		clearSearch: "Clear",
        save: 'Save',
		agree: 'Agree',
		ok: 'OK',
    }
}
